import React, { useState,useEffect,useRef } from 'react';
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "./LoadingSpinner";
import { Link,useNavigate } from "react-router-dom";
import Eventos from './Eventos';


function TopEventos2({isHome})
{

    let itop = 0;

    


     const [items2, setItems2] = useState();
     const [counter, setCounter] = useState(0);
     const [isLoading, setIsLoading] = useState(false);
     const [isVisible, setIsVisble] = useState('');     
     const [eventoActual, setEventoActual] = useState(null);

     async function fetchApi(top){
        setIsLoading(true);
        let fd = new FormData() 
        fd.append("top",top)

        const res = await axios.post('https://conectate-peru.com/api/ApiListarEventos.php',fd);
        setItems2(res.data.items);
        setIsVisble(res.data.items[0].visible);
        setIsLoading(false);
        
        const vEventoActual = JSON.parse(sessionStorage.getItem("EventoActual"));
        setEventoActual(vEventoActual)
     }

     const navigate = useNavigate();

    useEffect(() => {
        fetchApi(itop);
        
        },[])
    
        

        const handleClick1  = (e) => 
        {

            if(counter >= 0)
            {
            setCounter(counter + 4);

            fetchApi(counter + 4);
            }
            
            //console.log(counter);
        
        }

        const handleClick2  = (e) => 
        {

            if(counter > 0)
            {
            setCounter(counter - 4);
            
            fetchApi(counter - 4);
            }
            //console.log(counter);
            
        }

        const sliderRef = useRef(null);

    const scrollLeft = () => {
        sliderRef.current.scrollBy({
            left: -300,
            behavior: 'smooth'
        });
    };

    const scrollRight = () => {
        sliderRef.current.scrollBy({
            left: 300,
            behavior: 'smooth'
        });
    };
    
    const arrowButtonStyle = {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        border: 'none',
        padding: '1rem',
        cursor: 'pointer',
        zIndex: 1
    };
    
    const backToStartButtonContainerStyle = {
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        zIndex: 2
    };
    
    const backToStartButtonStyle = {
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        padding: '0.5rem 1rem',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    useEffect(() => {

        let isDragging = false; // Variable para rastrear si se está arrastrando
        let startX; // Posición inicial del touch al iniciar el arrastre
        let scrollLeft; // Posición inicial del scroll al iniciar el arrastre
        let currentPosition; // Posición actual del carrusel antes de salir
        
        const carrusel = document.getElementById('litopgrupos');
        
        carrusel.addEventListener('mousedown', (event) => {
            isDragging = true; // Comienza el arrastre
            startX = event.clientX; // Guarda la posición inicial del mouse
            scrollLeft = carrusel.scrollLeft; // Guarda la posición inicial del scroll
        });
        
        carrusel.addEventListener('touchstart', (event) => {
            isDragging = true; // Comienza el arrastre
            startX = event.touches[0].clientX; // Guarda la posición inicial del touch
            scrollLeft = carrusel.scrollLeft; // Guarda la posición inicial del scroll
        });
        
        carrusel.addEventListener('mouseup', (event) => {
            if (isDragging) {
                isDragging = false; // Termina el arrastre
                return; // Si se estaba arrastrando, no hacer nada más
            }
        
            // Si no se estaba arrastrando, verificar si fue un clic
            const clickX = event.clientX;
            const deltaX = Math.abs(clickX - startX);
        
            if (deltaX < 5) {
                // Si la distancia del clic es menor que 5px, avanzar al siguiente elemento
                const currentItemIndex = Math.floor(scrollLeft / carrusel.offsetWidth);
                const nextItemIndex = currentItemIndex + 1;
                carrusel.scrollTo({
                    left: nextItemIndex * carrusel.offsetWidth,
                    behavior: 'smooth'
                });
            }
        });
        
        carrusel.addEventListener('mousemove', (event) => {
            if (!isDragging) return; // Si no se está arrastrando, salir
        
            const x = event.clientX; // Posición actual del mouse
            const deltaX = x - startX; // Diferencia entre la posición inicial y la actual del mouse
            carrusel.scrollLeft = scrollLeft - deltaX; // Actualiza la posición del scroll
        });
        
        carrusel.addEventListener('touchmove', (event) => {
            if (!isDragging) return; // Si no se está arrastrando, salir
        
            const x = event.touches[0].clientX; // Posición actual del touch
            const deltaX = x - startX; // Diferencia entre la posición inicial y la actual del touch
            carrusel.scrollLeft = scrollLeft - deltaX; // Actualiza la posición del scroll
        });
        
        carrusel.addEventListener('mouseleave', () => {
            // Guardar la posición actual del carrusel al salir
            //currentPosition = carrusel.scrollLeft;
            isDragging = false;
        });
        
        window.addEventListener('mouseup', () => {
            // Restaurar la posición del carrusel cuando el mouse se levanta fuera del carrusel
            carrusel.scrollTo({
                left: currentPosition,
                behavior: 'smooth'
            });
        });
        
        window.addEventListener('touchend', () => {
            // Restaurar la posición del carrusel cuando el touch se levanta fuera del carrusel
            carrusel.scrollTo({
                left: currentPosition,
                behavior: 'smooth'
            });
        });


    }, []); // El segundo argumento [] asegura que este efecto solo se ejecute una vez después del montaje inicial

    return (
        <>
             <div style={{ position: 'relative', paddingBottom: 'calc(0.5em + 0.5vw)', border: 'none' }}>
            <button onClick={scrollLeft} style={{ ...arrowButtonStyle, left: '10px' }}>←</button>
            <ul 
                id='litopgrupos' 
                className="carrusel2" 
                ref={sliderRef} 
                style={{ 
                    display: 'flex', 
                    overflowX: 'scroll', 
                    scrollSnapType: 'x mandatory',
                    padding: '0', 
                    margin: '0', 
                    listStyleType: 'none' 
                }}
            >
                {!items2 ? (
                    <li style={{ padding: '1rem', textAlign: 'center' }}>Cargando Top Conectate....</li>
                ) : (
                    items2.map((item, index) => (
                        <li 
                            key={index} 
                            className='toplinew2' 
                            style={{
                                flex: '0 0 auto',
                                scrollSnapAlign: 'start',
                                marginRight: '1rem',
                                backgroundColor: '#fff',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: '10px',
                                padding: '1rem',
                                transition: 'transform 0.2s',
                                minWidth: '300px'
                            }}
                        >
                            <Eventos
                                ImagenPortada={item.ImagenPortada}
                                FechaInicio={item.FechaInicio}
                                FechaFin={item.FechaFin}
                                HoraInicio={item.HoraInicio}
                                HoraFin={item.HoraFin}
                                Titulo={item.Titulo}
                                Direccion={item.Direccion}
                                Participantes={item.Participantes}
                                IdEvento={item.Id}
                                IsHome={isHome}
                            />
                        </li>
                    ))
                )}
            </ul>
            <button onClick={scrollRight} style={{ ...arrowButtonStyle, right: '10px' }}>→</button>
           {/* <div style={backToStartButtonContainerStyle}>
                <button onClick={() => sliderRef.current.scrollTo({ left: 0, behavior: 'smooth' })} style={backToStartButtonStyle}>
                    Volver al inicio
                </button>
            </div>*/}
        </div>

        </>
    );
}

export default TopEventos2;