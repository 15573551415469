import React, { useState,useEffect } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import imgIsotipo from "../src/img/Home/isotipo.svg"
import imgIconVineta from "../src/img/Home/IconVineta.svg"
import imgLogo from '../src/img/Home/logo.svg';
import imgFutbol from '../src/img/Home/futbol.png';
import imgMusica from '../src/img/Home/musica.png';
import imgLupa from '../src/img/Home/lupa.svg';
import imgSend from '../src/img/Home/send.svg';

import imgFondo1 from '../src/img/Home/home_01.png';
import imgFondo2 from '../src/img/Home/home_02.png';
import imgFondo3 from '../src/img/Home/home_03.png';
import imgFondo4 from '../src/img/Home/home_04.png';
import imgFondo5 from '../src/img/Home/home_05.png';
import imgBtnEncuentra from '../src/img/Home/btnEncuentra.png';
import imgBtnCrea from '../src/img/Home/btnCrea.png';
import eslogan from '../src/img/Home/slogan.svg';

/**/

import TopEventos from './Components/TopEventos';
import TopEventos2 from './Components/TopEventos2';
import VideoPlayer from './Components/VideoPlayer';
import ReactPlayer from 'react-player';



export default function Home(){

const [items, setItems] = useState();
const [maestrosTipoOperacion, setMaestrosTipoOperacion] = useState();
const [operacion, setOperacion] = useLocalStorage('operacion',0);
const [show, setShow] = useState(false);
const [perfil, setPerfil] = useState('');
const [numerodocumento, setnumerodocumento] = useState('');
const [nombre, setNombre] = useState('');
const [apellido, setApellido] = useState('');
const [celular, setCelular] = useState('');
const [correo, setCorreo] = useState('');
const [contraseña, setContraseña] = useState('');
const [respuesta, setRespuesta] = useState('');
const [verSeleccionarPerfil, setSeleccionarPerfil] = useState(true)
const [verSeleccionarPerfilProfesional, setSeleccionarPerfilProfesional] = useState(false)
const [perfilSeleccionadoRegistro, setperfilSeleccionadoRegistro] = useLocalStorage('perfilSeleccionadoRegistro','')
const [tipoperfilseleccionadoRegistro, settipoperfilseleccionadoRegistro] = useLocalStorage('tipoperfilseleccionadoRegistro','')
const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
const [tipodocumento, settipodocumento] = useState('1');
const [idtipocliente, setIdtipocliente] = useLocalStorage('idtipocliente','')
const [tabIndex, setTabIndex] = useLocalStorage('tabIndex',0);
const [tabIndex2, setTabIndex2] = useLocalStorage('tabIndex2',0);


const navigate = useNavigate();

const handleRedirectLogin = () =>{

    const UsuActivo = sessionStorage.getItem("UsuarioActivo");
    

    /*if(UsuActivo != null)
    {
        navigate('/CrearGrupo');
    }else{
        navigate('/Login');
    }*/

    navigate('/ListaGrupos');

  }

  const handleRedirectLoginCrea = () =>{

    const UsuActivo = sessionStorage.getItem("UsuarioActivo");
    

    if(UsuActivo != null)
    {
        navigate('/CrearGrupo');
    }else{
        navigate('/Login');
    }

    //navigate('/HomeUser');

  }

  const handleRedirectRegistro = () =>{

    const UsuActivo = sessionStorage.getItem("UsuarioActivo");
    

    if(UsuActivo != null)
    {
        navigate('/HomeUser');
    }else{
        navigate('/Registro');
    }

    //navigate('/HomeUser');

  }


  useEffect(() => {
    // Obtener la fecha actual
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    
    // Obtener la marca de tiempo almacenada en localStorage
    const lastOpenedDate = localStorage.getItem('lastOpenedDate');
    
    // Si no hay una marca de tiempo almacenada o si la marca de tiempo almacenada no es del mismo día que hoy, entonces es la primera vez que se abre en el día
    if (!lastOpenedDate || (new Date(lastOpenedDate).getDate() !== currentDay || new Date(lastOpenedDate).getMonth() !== currentMonth || new Date(lastOpenedDate).getFullYear() !== currentYear)) {
        
        if(!lastOpenedDate)
        {
          sessionStorage.clear();
          localStorage.clear();
        }
      
        // Aquí puedes realizar cualquier acción que necesites realizar cuando la página se abra por primera vez en el día
      console.log('La página se abrió por primera vez hoy.');
      
      // Actualizar la marca de tiempo en localStorage
      localStorage.setItem('lastOpenedDate', currentDate);
    }else{
        sessionStorage.clear();
        localStorage.clear();
    }
  }, []);

  function BuscarContenido()
    {
    const formulario = document.querySelector('#txtsearch');

    findString(formulario.value.toUpperCase());
    }


    function findString (str) 
    {
        if (parseInt(navigator.appVersion) < 4) return;
  let strFound = false;

  if (window.find) {
    // Código para navegadores que admiten window.find
    strFound = window.find(str);
    if (!strFound) {
      strFound = window.find(str, 0, 1);
      while (window.find(str, 0, 1)) continue;
    }
  } else if (navigator.appName.indexOf("Microsoft") !== -1) {
    // Código específico para Internet Explorer
    let TRange = window.document.body.createTextRange();
    TRange.collapse(false);
    strFound = TRange.findText(str);
    if (strFound) TRange.select();
    if (!TRange || strFound === 0) {
      TRange = window.document.body.createTextRange();
      strFound = TRange.findText(str);
      if (strFound) TRange.select();
    }
  } else if (navigator.appName === "Opera") {
    // Opera no soportado
    alert("Los navegadores Opera no son soportados. Disculpe las molestias.");
    return;
  }

    if (!strFound) 
        navigate('/HomeUser');
    return strFound;
    }

    

return(
    <>
    <Layout>
    {/*<div style={{backgroundColor:'rgba(0, 255, 237, 0.1)'}}>*/}
    <Container className='containerImageHome'>
    <div className='d-none d-sm-block'>
    <VideoPlayer></VideoPlayer>
    </div>
    
    <div className="video-overlay">
        
    </div>
        <Row style={{paddingBottom:'3vw'}}>
            <Col className='d-none d-sm-block' style={{padding:'1vw 0 0 0'}} sm={12}>
            <img src={imgIsotipo} className='imgisotipo_f' alt="Isotipo"></img>
            </Col>
        </Row>
        <Row style={{paddingBottom:'1vw'}}>
            <Col sm={12}>
                <center>
                <img id='imgLogoPrincipal' src={imgLogo} className='LogoPrincipal' alt="Logo Principal"></img>
                </center>
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                <center><img src={eslogan} className='SloganPrincipal'></img></center>
            </Col>
        </Row>
        <Row style={{paddingTop:'4vw'}}>
            <Col sm={1}>
            </Col>
            <Col sm={10}>
                <center>
                <p className='TextoHomePrincipal'><b>Nuestro propósito es brindarte un espacio de conexión y encuentro con personas de interes similares, donde podrás participar en todo tipo de eventos y actividades, nutrir tu talento literario, aprender un nuevo idioma o practicar tu deporte favorito, las posibilidades son infinitas. Aquí fomentamos la diversidad y la conexión entre todo tipo de personas, y todo esto en un espacio seguro y de bienestar.</b></p>
                </center>
               
            </Col>
            <Col sm={1}>
            </Col>
        </Row>
        <Row className='minHeight'>
            <center>
            <Col sm={12}>
            <div className='react-padre'>
            <p className='lblBienvenida'><b style={{color:'#92BB00'}}>Bienvenido a nuestra Comunidad <br></br>¡Conéctate!</b></p>
            <div className='d-block d-sm-none'>
            <ReactPlayer
                url="/videos/home/conectateperu.mp4"  // Reemplaza con la ruta de tu video local
                className='react-player-2'
                
                playing muted loop
                />
            </div>
            </div>
            
            </Col>
            </center>
        </Row>
        <Row>
            <Col className="d-none d-sm-block" sm={2}>
                {/*<img src={imgFutbol} style={{height:'250px',float:'right'}}></img>
                d-flex justify-content-center align-items-center h-100
                */}
            </Col>
            <center>
            <Col sm={8} xs={12} >
            <div className='tamaniobuscador' >
            <div className='text-white'>
                 <Container style={{backgroundColor:'white'}}>
                    <Row>
                        <Col xs={1}>
                            <img src={imgLupa} className='imglupa'></img>
                        </Col>
                        <Col xs={9}>
                        <input id='txtsearch' placeholder='Buscar evento'></input>
                        </Col>
                        <Col xs={2}>
                            <button onClick={BuscarContenido} className='btn-primarybuscar d-none d-sm-block'>Buscar</button>
                            <button className='btn-primarybuscar d-block d-sm-none'><img src={imgSend} style={{height:'40px'}}></img></button>
                        </Col>
                    </Row>
                 </Container>
            </div>
            </div>
          
            </Col>
            </center>
            <Col className="d-none d-sm-block" sm={2}>
            {/*<img src={imgMusica} style={{height:'250px',float:'left'}}></img>*/}
            </Col>
        </Row>
        <Row style={{paddingTop:'5vw'}}>
            <Col className='btnConectarme' sm={12}>
                <center>
                <Button onClick={handleRedirectRegistro} className='btn-primary4'>Quiero unirme</Button>
                </center>
            </Col>
        </Row>
        </Container>
        {/*</div>*/}
        <Container style={{paddingLeft:'0',paddingTop:'calc(1em + 1vw)',minHeight:'calc(20em + 20vw)'}} >
        
        <Row>
            <Col sm={1}>
            </Col>
            <Col sm={10} style={{paddingTop:'2vw'}}>
                <p style={{fontSize:'18pt',float:'left',fontStyle:'italic',paddingLeft:'20px'}}><b style={{color:'#484848'}}>¿Y hoy que planes?</b></p>
            </Col>
            <Col sm={1}>
            </Col>
        </Row>
        <Row>
        <Col sm={1}>
            </Col>
            <Col sm={10}>
                <ul id='divPlanes_2' className='divPlanes_2'>
                    <li className='planes_2'>
                        <img src={imgFondo1}></img>
                        <p style={{fontSize:'11pt',fontStyle:'italic'}}><b style={{color:'#FF2D52'}}>Compite</b></p>
                    </li>
                    <li className='planes_2'>
                    <img src={imgFondo2} ></img>
                    <p style={{fontSize:'11pt',fontStyle:'italic'}}><b style={{color:'#FF2D52'}}>Expresate</b></p>
                    </li>
                    <li className='planes_2'>
                    <img src={imgFondo3} ></img>
                    <p style={{fontSize:'11pt',fontStyle:'italic'}}><b style={{color:'#FF2D52'}}>Crea</b></p>
                    </li>
                    <li className='planes_2'>
                    <img src={imgFondo4} ></img>
                    <p style={{fontSize:'11pt',fontStyle:'italic'}}><b style={{color:'#FF2D52'}}>Diviertete</b></p>
                    </li>
                    <li className='planes_2'>
                    <img src={imgFondo5} ></img>
                    <p style={{fontSize:'11pt',fontStyle:'italic'}}><b style={{color:'#FF2D52'}}>Conéctate</b></p>
                    </li>
                    
                </ul>
            </Col>
            <Col sm={1}>
            </Col>
        </Row>
        <Row style={{paddingTop:'70px'}}>
            <Col sm={2}>
            </Col>
            <Col sm={8}>
               <Container>
                    <Row>
                    <Col className="d-none d-sm-block" sm={2}>
                    </Col>
                    <center>
                    <Col sm={8} xs={12} >
                    <div className='tamaniobuscador' >
                    <div className='text-white'>
                        <Container style={{backgroundColor:'white'}}>
                            <Row>
                                <Col xs={1}>
                                    <img src={imgLupa} className='imglupa'></img>
                                </Col>
                                <Col xs={9}>
                                <input id='txtsearch' placeholder='Encuentra tu grupo'></input>
                                </Col>
                                <Col xs={2}>
                                    <button onClick={handleRedirectLogin} className='btn-primarybuscar d-none d-sm-block'>Buscar</button>
                                    <button className='btn-primarybuscar d-block d-sm-none'><img src={imgSend} style={{height:'40px'}}></img></button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    </div>
                
                    </Col>
                    </center>
                    <Col className="d-none d-sm-block" sm={2}>
                    {/*<img src={imgMusica} style={{height:'250px',float:'left'}}></img>*/}
                    </Col>
                </Row>
               </Container>
            </Col>
            <Col sm={2}>
            </Col>
        </Row>

        <Row style={{paddingTop:'100px'}}>
        <Col sm={2}>
            </Col>
            <Col sm={8}>
                <Container>
                    <Row style={{paddingBottom:'20px'}}>
                        <Col sm={2} xs={3} className='imgisotipo'>
                        <div><img className='imgmobilvineta' src={imgIconVineta} ></img></div>
                        </Col>
                        <Col sm={10} xs={9}>
                            <p className='textHomeVinetas'><b>Tendrás la posibilidad de crear tu propio grupo y eventos.</b></p>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom:'20px'}}>
                        <Col sm={2} xs={3} className='imgisotipo'>
                        <div><img className='imgmobilvineta' src={imgIconVineta} ></img></div>
                        </Col>
                        <Col sm={10} xs={9}>
                            <p className='textHomeVinetas'><b>Diversidad de actividades de todo y para todos.</b></p>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom:'20px'}}>
                        <Col sm={2} xs={3} className='imgisotipo'>
                        <div><img className='imgmobilvineta' src={imgIconVineta} ></img></div>
                        </Col>
                        <Col sm={10} xs={9}>
                            <p className='textHomeVinetas'><b>Espacio ideal para encontrar tu “Tribu”, conectarte en línea o en persona.</b></p>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <Col sm={2}>
            </Col>
        </Row>
       <Row style={{paddingTop:'100px'}}>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
        <p className='tituloEventos'>Eventos <b>del día</b></p>
        <TopEventos2 isHome={true}></TopEventos2>
        </Col>
        <Col sm={1}>
        </Col>
       </Row>
         <Container>
            <Row>
                <Col sm={5}>
                </Col>
                <Col sm={2}>
                <center>
        {/*<Button variant='primary3' onClick={handleRedirectLogin}>
                                    <b>Crea tu grupo</b>
                                    </Button>*/}
        </center>
                </Col>
                <Col sm={5}>
                </Col>
            </Row>
        </Container>   
        
        
    </Container>

    </Layout>
</>
);

}