import React, {useState,useEffect} from "react";
import ReactDOM from 'react-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Login from './Login';
import { Link,useNavigate,Navigate } from "react-router-dom";
import {Button,Hidden} from 'react-bootstrap';
import axios from 'axios'; // npm i axios

/*Imagenes*/

/*import imgLogo from "../../src/img/logo_new.svg"
import imgBtnObtenCredito from "../../src/img/btn_01.svg"
import imgBtnEncuentraAgente from "../../src/img/btn_02.svg"
import imgBtnPublicaGratis from "../../src/img/btn_03.svg"
import imgBtnIniciaSesion from "../../src/img/btn_04.svg"*/
import imgBtnHamburguesa from "../../src/img/Home/menu_burger.svg"
import imgLogo from "../../src/img/Home/logo.svg"
import { useLocalStorage } from "../useLocalStorage";


function Header2()
{
    const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
    const [idtipousuario, setIdtipousuario] = useLocalStorage('idtipousuario','')
    const [destipousuario, setDestipousuario] = useLocalStorage('destipousuario','')
    const [idtipocliente, setIdtipocliente] = useLocalStorage('idtipocliente','')
    const [tabIndex, setTabIndex] = useLocalStorage('tabIndex',0);
    const [shouldRedirect, setShouldRedirect] = useLocalStorage('shouldRedirect',false);
    const [isMobil, setIsMobil] = useState(false);

    

    const [usuarioActivo,setusuarioActivo] = useState(() => {

        const UsuActivo = sessionStorage.getItem("UsuarioActivo");
        
        if(UsuActivo == null)
        {
            return;
        }
        
        const UsuActivoS  = JSON.parse(UsuActivo);
    
        return UsuActivoS || "";
        });
    
        const navigate = useNavigate();

        const handleRedirect = () => {
      
          if(window.confirm('¿Desea cerrar sesión?'))
          {
              sessionStorage.clear();
              localStorage.clear();
              navigate('/');
              window.location.reload(true);
          }
                    
      }

      const handleRedirectLogin = () =>{

        const UsuActivo = sessionStorage.getItem("UsuarioActivo");

        if(UsuActivo != null)
        {
            navigate('/RegistroAnuncio');
        }

      }

      useEffect(() => {
        //fetchApi();
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(userAgent);
        setIsMobil(isMobile);
      },[])

      useEffect(() => {
        const handleBeforeUnload = (event) => {
          // Realiza aquí cualquier acción que necesites realizar antes de que la página se cierre
          console.log('La página se está cerrando...');
          sessionStorage.clear();
          localStorage.clear();
          // Puedes mostrar un mensaje al usuario si lo deseas
          event.returnValue = ''; // Esto es necesario en algunos navegadores para mostrar un mensaje personalizado al usuario
        };
    
        // Adjunta el event listener al evento 'beforeunload' cuando se monta el componente
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Retira el event listener cuando el componente se desmonta
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);


        function mostraratencion()
        {
        
        var x = document.getElementById("popup1");

        if (x.className === "popupmenu") {
            x.className += " activo";
           
        } else {
            x.className = "popupmenu";
        }
        }

        function mostrarMenuFlotante()
        {
        
        var x = document.getElementById("popup2");
        var x2 = document.getElementById("popup1");
        x2.className = "popupmenu";
        

        if (x.className === "popupmenu2") {
            x.className += " activo";
            
        } else {
            x.className = "popupmenu2";
        }
        }

        const handleClickOpcion = (opcion) =>{

            setTabIndex(opcion);
            
            switch (opcion) {
                case 0:
                    navigate('/DetalleUsuario');
                    break;
                case 1:
                    navigate('/DetalleUsuarioI');
                    break;
                case 2:
                    navigate('/DetalleUsuarioV');
                    break;
                case 3:
                    navigate('/DetalleUsuarioM');
                    break;
            
                default:
                    break;
            }
            
        }

        const handleRedirectListaGrupo = () => {

            const UsuActivo = sessionStorage.getItem("UsuarioActivo");

            if(UsuActivo != null)
            {
                navigate('/ListaGrupoUsuario');
            }else{
                alert("Debe iniciar sesión para ver lista de grupo.")
                navigate('/');
            }
          
          }

          const handleRedirectListaEvento = () => {

            const UsuActivo = sessionStorage.getItem("UsuarioActivo");

            if(UsuActivo != null)
            {
                navigate('/ListaEventoUsuario');
            }else{
                alert("Debe iniciar sesión para ver lista de eventos.")
                navigate('/');
            }
          
          }
       

        const handleRedirectProyecto = () => {

            const UsuActivo = sessionStorage.getItem("UsuarioActivo");

            if(UsuActivo != null)
            {
                
            }else{
                //alert("Debe iniciar sesión para crear grupo.")
                navigate('/Registro');
            }
          
          }

          const handleRedirectMiPerfil = () => {

            const UsuActivo = sessionStorage.getItem("UsuarioActivo");

            if(UsuActivo != null)
            {
                navigate('/MiPerfil');
            }else{
                alert("Debe iniciar sesión para crear grupo.")
                navigate('/');
            }
          
          }

          const handleRedirectInciarSession = () => {

            navigate('/Login');
          
          }

          

          const handleRedirectHome = () => {

            navigate(-1)
          
          }

      window.addEventListener("scroll", function () {
         let header = document.getElementById("headerprincipal");
         let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
         if(header !=null || header != undefined)
         {
            header.style.opacity = 2 - scrollTop / 200;
         }
       
         
      });

      async function fetchApi(){
 
        let fd = new FormData() 
        fd.append("pidusuario",idusuario)
     
        
     
        const res3 = await axios.post('https://speedaixdev.com/api/ApiObtenerUsuariof.php', fd);
        
        res3.data.items.map((item, index) => {
     
         setIdtipousuario(item.idTipo_Usuario);
         setDestipousuario(item.Destipousuario);
         //setIdtipocliente(item.idTipo_Cliente);
         sessionStorage.setItem("selecttipousuario",item.idTipo_Cliente);
        
         
        })

        
    }

 

    return (

        //<header id='headerprincipal' style={{width:'100%',padding:'20px',boxSizing:'border-box',border:'1px solid black',////background:'black',position:'fixed',zIndex:'1'}}>
        <header  id="headerprincipal">
             <div id='popup1' className="popupmenu" onMouseLeave={mostraratencion}>
                <ul className="popupmenu_ul">
               

                {(usuarioActivo == null || usuarioActivo == '') ? <>
                <li><a style={{fontSize:'10pt'}}  onClick={handleRedirectInciarSession}>Inicia Sesión</a></li>
                <li><a style={{fontSize:'10pt'}}  onClick={(e) => handleRedirectProyecto()}>Crear Grupo</a></li>
                                    </> : <>
                                    <li><a style={{fontSize:'10pt'}}  onClick={handleRedirectListaGrupo}>Mis Grupos</a></li>
                                    <li><a style={{fontSize:'10pt'}}  onClick={handleRedirectListaEvento}>Mis Eventos</a></li>
                                    <li><a style={{fontSize:'10pt'}}  onClick={handleRedirectProyecto}>Crear Grupo</a></li>
                                    <li><a style={{fontSize:'10pt'}} onClick={handleRedirectMiPerfil}>Mi Perfil</a></li>
                                    <li><a style={{fontSize:'10pt'}}  onClick={(e) => handleRedirect()}>Cerrar Sesión</a></li>
                                    </>}


                

                </ul>
                </div>
                <Container style={{padding: '0.5rem 0 0 0', minHeight: 'calc(1.5em + 1.5vw)'}}>
                    <Row>
                        <Col sm={12}>
                        <Container>
                            <Row>
                            <Col sm={4} xs={0}>

                            </Col>
                            <Col sm={4} xs={6}>
                                { /*(isMobil == true) ? <></> : <></>*/}
                                <center>
                                <img
                                    src={imgLogo}
                                    className="LogoHeader"
                                    alt="Logo"
                                    onClick={handleRedirectHome}
                                    />
                                </center>
                            </Col>
                            <Col sm={4} xs={6}>
                                <Container style={{ padding: '0' }}>
                                <Row>
                                    <Col sm={2} xs={0}></Col>
                                    <Col sm={4} xs={6}>
                                    

                                    {(usuarioActivo == null || usuarioActivo === '') ? (
                                       <Button
                                       className="d-none d-sm-block"
                                       variant="primary3"
                                       onClick={(e) => handleRedirectProyecto()}
                                   >
                                       <b>Registrarse</b>
                                   </Button>
                                    ) : (
                                        <center>
                                        
                                        </center>
                                    )}




                                    </Col>
                                    <Col sm={4} xs={3}>
                                    {(usuarioActivo == null || usuarioActivo === '') ? (
                                        <Button
                                        className="d-none d-sm-block"
                                        variant="primary2"
                                        onClick={(e) => handleRedirectInciarSession()}
                                        >
                                        <b>Inicia sesión</b>
                                        </Button>
                                    ) : (
                                        <center>
                                        <div className="circle blue">
                                            <center>
                                            <p style={{ padding: '5px 0 0 0' }}>
                                                {((usuarioActivo == null || usuarioActivo === '') ? null : usuarioActivo.substring(0, 1).toUpperCase())}
                                            </p>
                                            </center>
                                        </div>
                                        </center>
                                    )}
                                    </Col>
                                    <Col sm={2} xs={3} style={{textAlign:'right'}}>
                                   
                                        <img src={imgBtnHamburguesa} className="d-lg-none d-sm-block" onClick={mostraratencion} style={{ cursor: 'pointer', }} alt="Hamburguesa" />
                                   
                                    </Col>
                                </Row>
                                </Container>
                            </Col>
                            </Row>
                        </Container>
                        </Col>
                    </Row>
                </Container>
        </header>
        


    )


}


export default Header2;